import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/tyo/CRUD')),
};

const FAC = {
	Dashboard: lazy(() => import('../views/sodiq/BudgetDashboard')),
	PlanVSActual: lazy(() => import('../views/sodiq/PlanVSActual')),
	ImportData: lazy(() => import('../views/sodiq/BudgetPlan')),
	ActualExpense: lazy(() => import('../views/sodiq/ActualExpense')),
	CostCenterPIC: lazy(() => import('../views/bakti/cost-center-pic')),
};

const Master = {
	MasterGLAccount: lazy(() => import('../views/rio/master-gl-account')),
	MasterCostCenter: lazy(() => import('../views/putrair/master-cost-center')),
};
const Budget = {
	ImportBudget: lazy(() => import('../views/handri/ImportBudget')),
	ImportBudgetCapex: lazy(() => import('../views/ninda/ImportBudgetCapex')),
	ImportBudgetSales: lazy(() => import('../views/rio/import-budget-sales/index')),
};

const Approval = {
	ApprovalBudget: lazy(() => import('../views/sodiq/ApprovalBudget')),
};

const GLMapping = {
	GlMapping: lazy(() => import('../views/ninda/GLMapping'))
}


const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <FAC.Dashboard />,
		index: 'BudgetDashboard',
		exact: true,
	},
	{
		path: null,
		element: <FAC.PlanVSActual />,
		index: 'PlanVSActual',
		exact: true,
	},
	{
		path: null,
		element: <FAC.ImportData />,
		index: 'BudgetPlan',
		exact: true,
	},
	{
		path: null,
		element: <FAC.ActualExpense />,
		index: 'ActualExpense',
		exact: true,
	},
	{
		path: null,
		element: <FAC.CostCenterPIC />,
		index: 'CostCenterPIC',
		exact: true,
	},
	{
		path: null,
		element: <Master.MasterGLAccount />,
		index: 'MasterGLAccount',
		exact: true,
	},
	{
		path: null,
		element: <Master.MasterCostCenter />,
		index: 'MasterCostCenter',
		exact: true,
	},
	{
		path: null,
		element: <Budget.ImportBudget />,
		index: 'ImportBudget',
		exact: true,
	},
	{
		path: null,
		element: <Budget.ImportBudgetCapex />,
		index: 'ImportBudgetCapex',
		exact: true,
	},
	{
		path: null,
		element: <Budget.ImportBudgetSales />,
		index: 'ImportBudgetSales',
		exact: true,
	},
	{
		path: null,
		element: <Approval.ApprovalBudget />,
		index: 'ApprovalBudget',
		exact: true,
	},
	{
		path: null,
		element: <GLMapping.GlMapping />,
		index: 'GLMapping',
		exact: true
	}
];
export default contents;
